const profileNavbar = [
  {
    label: 'My Babies',
    icon: 'person',
    path: 'babies',
  },
  {
    label: 'Speach Synth',
    icon: 'volume-up',
    path: 'speach-synth',
  },
  {
    label: 'Lesson',
    icon: 'time',
    path: 'lesson',
  },
]

export default profileNavbar
