import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { removeAuthData, removeSessionData } from '../../lib/auth'
import type { LocationState } from '../../models'
import apolloClient from '../../lib/apollo-client'
import { useGlobalState } from '../../state'

const LogoutPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [, setCourses] = useGlobalState('courses')
  const [, setCourseCards] = useGlobalState('courseCards')
  const [, setUser] = useGlobalState('user')

  useEffect(() => {
    setCourses(null)
    setCourseCards(null)
    setUser(null)

    apolloClient.clearStore()

    removeAuthData()
    removeSessionData()

    const locationState = location.state as LocationState

    if (!locationState?.isGlobalLogout) {
      // To support global logging out from all windows/tabs.
      window.localStorage.setItem('globalLogout', Date.now().toString())
    }

    if (locationState?.prevLocation) {
      navigate(`/login`, {
        state: { prevLocation: locationState.prevLocation },
      })
    } else {
      navigate('/login')
    }
  }, [])

  return null
}

export default LogoutPage
