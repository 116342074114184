import React, { useCallback, useEffect, useState } from 'react'
import type { CourseItem } from '../../models'
import CourseNavbarItemLink from './CourseNavbarItemLink'
import CourseNavbarItemTitle from './CourseNavbarItemTitle'
import { NavGroup as NavGroup, NavChevron } from './styled'
import { useLesson } from '../../hooks'
import { NavigateLessonMessage, NavigateLessonPayload } from '../../messages'

type CourseNavbarItemProps = {
  isRoot?: boolean
  item: CourseItem
}

const CourseNavbarItem = (props: CourseNavbarItemProps) => {
  const { isRoot, item } = props

  const { weekNum, dayNum } = useLesson()
  const [isExpanded, setExpanded] = useState(item.isExpanded)

  useEffect(() => {
    const subsription = PubSub.subscribe(
      NavigateLessonMessage,
      (message, { weekNum, dayNum }: NavigateLessonPayload) => {
        if (
          item.position.weekNum == weekNum &&
          (!item.position.dayNum || item.position.dayNum == dayNum)
        ) {
          setExpanded(true)
        }
      },
    )
    return () => {
      PubSub.unsubscribe(subsription)
    }
  }, [])

  useEffect(() => {
    if (
      item.position.weekNum == weekNum &&
      (!item.position.dayNum || item.position.dayNum == dayNum)
    ) {
      setExpanded(true)
    }
  }, [item, dayNum, weekNum])

  const toggleExpanded = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    setExpanded(!isExpanded)
  }

  const drawChildren = useCallback(() => {
    return (
      <>
        {item.children.map((child, index) => (
          <CourseNavbarItem key={index} item={child} />
        ))}
      </>
    )
  }, [item.children])

  if (isRoot) {
    return drawChildren()
  }

  if (!item.children.length) {
    return <CourseNavbarItemLink item={item} />
  }

  return (
    <>
      <NavGroup onClick={toggleExpanded} completed={+item.isCompleted}>
        <CourseNavbarItemTitle item={item} />
        <NavChevron className={`${isExpanded && 'expanded'}`} />
      </NavGroup>

      {isExpanded && drawChildren()}
    </>
  )
}

export default CourseNavbarItem
