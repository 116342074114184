import { toWords } from 'number-to-words'

const synth = window.speechSynthesis

export type SpeechSynthOptions = {
  voice: SpeechSynthesisVoice
  pitch: number
  rate: number
}

export const getVoices = () => {
  const voices = synth.getVoices().sort(function (a, b) {
    const aname = a.name.toUpperCase()
    const bname = b.name.toUpperCase()

    if (aname < bname) {
      return -1
    } else if (aname == bname) {
      return 0
    } else {
      return +1
    }
  })

  return voices
}

export const speak = (text: string, options: SpeechSynthOptions) => {
  if (!text) {
    return
  }

  const utterance = new SpeechSynthesisUtterance(text)

  utterance.onerror = function (event) {
    console.error('SpeechSynthesisUtterance.onerror:', event)
  }

  utterance.voice = options.voice
  utterance.pitch = options.pitch
  utterance.rate = options.rate

  synth.speak(utterance)
}
