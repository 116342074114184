import { useCallback, useEffect, useState } from 'react'
import Content from '../../components/Content'
import Sidebar from '../../components/Sidebar'
import CourseNavbar from '../../components/CourseNavbar'
import { useMutation } from '@apollo/client'
import {
  GQL_GET_OR_CREATE_COURSE,
  GetOrCreateCourseDto,
} from '../../api/course/get-or-create-course'
import type { Course } from '../../models'
import {
  useBaby,
  useCourseCards,
  useCourse,
  useCourseNavigation,
  useDebug,
  useFullScreen,
  usePrevious,
} from '../../hooks'
import Spinner from '../../components/Spinner'
import { Outlet, useParams } from 'react-router-dom'
import type { CardPoints, CourseName } from '../../types'
import type { GenerateCardPayload } from '../../messages/generate-cards.message'
import { flagObject } from '../../lib/utils'

const createWorker = () => {
  const worker = new Worker(
    new URL('../../workers/card-generator.ts', import.meta.url),
    {
      type: 'module',
    },
  )
  worker.onerror = (error: ErrorEvent) => {
    console.error('Worker: CardGenerator: Error:', error)
  }
  return worker
}

function CountsPage() {
  const params = useParams()
  const [currentBaby] = useBaby()
  const previousBaby = usePrevious(currentBaby, null)
  const [course, setCourse] = useCourse()
  const [courseCards, setCourseCards] = useCourseCards()
  const { navigateNextLesson } = useCourseNavigation()
  const [isFullScreen] = useFullScreen()
  const [worker, setWorker] = useState<Worker | null>(null)
  const isDebug = useDebug()

  const [getOrCreateCourse, { data, loading, error }] = useMutation<
    { getOrCreateCourse: Course },
    { payload: GetOrCreateCourseDto }
  >(GQL_GET_OR_CREATE_COURSE, {
    variables: {
      payload: {
        babyId: currentBaby?._id || '',
        courseName: params.course as CourseName,
      },
    },
    notifyOnNetworkStatusChange: true,
  })

  const terminateWorker = useCallback(() => {
    if (!worker) return

    worker.terminate()
    setWorker(null)

    if (isDebug) {
      console.log('Worker: CardGenerator: Terminated')
    }
  }, [isDebug, worker])

  const startWorker = useCallback(() => {
    if (!worker) return
    if (!courseCards) return
    if (courseCards.isPrepared) return

    const msg: GenerateCardPayload = {
      minCard: course?.cards.minCard ?? '0',
      maxCard: course?.cards.maxCard ?? '0',
      generatedCards: flagObject(courseCards.cardPoints, true),
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
      isDebug,
    }
    worker.postMessage(msg)

    if (isDebug) {
      console.log('Worker: CardGenerator: Started:', msg)
    }
  }, [
    courseCards,
    course?.cards.maxCard,
    course?.cards.minCard,
    isDebug,
    worker,
  ])

  useEffect(() => {
    // Terminate the worker on page leaving.
    return () => {
      terminateWorker()
    }
  }, [])

  useEffect(() => {
    if (!worker) return

    worker.onmessage = (
      message: MessageEvent<{
        msg: string
        cardPoints: CardPoints
      }>,
    ) => {
      if (isDebug) {
        console.log(
          'Worker: CardGenerator: OnMessage:',
          'msg=',
          message.data.msg,
          'data=',
          message.data,
        )
      }
      if (message.data.msg === 'partiallyDone' || message.data.msg === 'done') {
        setCourseCards((courseCards) => {
          const newCourseCards = {
            cardPoints: {
              ...(courseCards?.cardPoints ?? {}),
              ...message.data.cardPoints,
            },
            isPrepared: message.data.msg === 'done',
          }
          return newCourseCards
        })

        if (message.data.msg === 'done') {
          terminateWorker()
        }
      }
    }
  }, [worker, currentBaby, setCourseCards, isDebug, terminateWorker])

  useEffect(() => {
    async function fetchData() {
      if (!course && currentBaby) {
        const res = await getOrCreateCourse()
        setCourse(res.data?.getOrCreateCourse || null)
      }
    }
    fetchData().then(() => {
      // Navigate next lesson only when current baby was changed
      // and only when a course is loaded already.
      if (currentBaby != previousBaby) {
        terminateWorker() // Worker will be started automatically by another effect if needed.
        navigateNextLesson()
      }
    })
  }, [
    course,
    currentBaby,
    getOrCreateCourse,
    isDebug,
    navigateNextLesson,
    previousBaby,
    setCourse,
    terminateWorker,
  ])

  useEffect(() => {
    if (worker) return
    if (!course) return
    if (!courseCards) return
    if (courseCards.isPrepared) return

    // If worker has been deleted then create new one automatically.
    // Create it only if cards are not processed.
    setWorker(createWorker)
  }, [worker, course, courseCards])

  useEffect(() => {
    if (!worker) return

    // If worker has been created then start it automatically.
    startWorker()
  }, [worker])

  return (
    <>
      <Sidebar
        title={course?.title}
        themeKey="countsSidebar"
        hidden={isFullScreen}
      >
        <CourseNavbar />
      </Sidebar>
      <Content padding={0} themeKey="countsContent">
        {loading && <Spinner />}
        <Outlet />
      </Content>
    </>
  )
}

export default CountsPage
