import * as React from 'react'
import { cx } from '@emotion/css'

// This is a helper function to inject the classname.
export function withClass(className?: string) {
  return (Component: React.ReactComponentElement) => {
    const ComponentWithCustomClass = (props: any) => {
      return <Component {...props} className={cx(props.className, className)} />
    }

    return ComponentWithCustomClass
  }
}
