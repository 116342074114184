import dotenv from 'dotenv'
dotenv.config()

import React from 'react'
import ReactDOM from 'react-dom'
import { hot } from 'react-hot-loader'
import App from './components/App'

import './index.scss'

declare const module: unknown
export default hot(module)(App)

ReactDOM.render(<App />, document.getElementById('root'))
