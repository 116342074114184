import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'
import type { Course, CoursePosition } from '../models'
import { useLesson } from './use-lesson'
import { useCourse } from './use-course'
import { useMutation } from '@apollo/client'
import { GQL_SET_COURSE_POSITION, SetCoursePositionDto } from '../api'
import PubSub from 'pubsub-js'
import {
  NavigateLessonMessage,
  NavigateLessonPayload,
  PlayLessonMessage,
} from '../messages'
import { useDebug } from './use-debug'

const isZeroPosition = (position: CoursePosition) => {
  return (
    position.weekNum == 0 && position.dayNum == 0 && position.lessonNum == 0
  )
}

const getNextPosition = (course: Course): CoursePosition => {
  if (isZeroPosition(course.position)) {
    return {
      weekNum: 1,
      dayNum: 1,
      lessonNum: 1,
    }
  }

  const week = course.weeks[course.position.weekNum - 1]
  const day = week.days[course.position.dayNum - 1]
  const lesson = day.lessons[course.position.lessonNum - 1]

  let nextLessonNum =
    lesson.number < day.lessons.length ? lesson.number + 1 : -1
  let nextDayNum =
    nextLessonNum === -1
      ? day.number < week.days.length
        ? day.number + 1
        : -1
      : day.number
  let nextWeekNum =
    nextDayNum === -1
      ? week.number < course.weeks.length
        ? week.number + 1
        : -1
      : week.number

  if (nextWeekNum == -1) {
    // Stuck on the last lesson.
    nextWeekNum = week.number
    nextDayNum = day.number
    nextLessonNum = lesson.number
  } else {
    // Go to the next lesson.
    if (nextDayNum == -1) nextDayNum = 1
    if (nextLessonNum == -1) nextLessonNum = 1
  }

  return {
    weekNum: nextWeekNum,
    dayNum: nextDayNum,
    lessonNum: nextLessonNum,
  }
}

const getPrevPosition = (
  course: Course,
  currPosition: CoursePosition,
): CoursePosition => {
  const week = course.weeks[currPosition.weekNum - 1]
  const day = week?.days[currPosition.dayNum - 1]
  const lesson = day?.lessons[currPosition.lessonNum - 1]

  let prevLessonNum = 1 < lesson.number ? lesson.number - 1 : -1
  let prevDayNum =
    prevLessonNum === -1 ? (1 < day.number ? day.number - 1 : -1) : day.number
  let prevWeekNum =
    prevDayNum === -1 ? (1 < week.number ? week.number - 1 : -1) : week.number

  if (prevWeekNum == -1) {
    // Stuck on the empty lesson.
    prevWeekNum = 0
    prevDayNum = 0
    prevLessonNum = 0
  } else {
    // Go to the prev lesson.
    if (prevDayNum == -1) prevDayNum = week.days.length
    if (prevLessonNum == -1) prevLessonNum = day.lessons.length
  }

  return {
    weekNum: prevWeekNum,
    dayNum: prevDayNum,
    lessonNum: prevLessonNum,
  }
}

export const useCourseNavigation = () => {
  const { courseName, weekNum, dayNum, lessonNum } = useLesson()
  const [course, setCourse] = useCourse()
  const navigate = useNavigate()
  const isDebug = useDebug()

  const [setCoursePosition, { loading }] = useMutation<
    void,
    { payload: SetCoursePositionDto }
  >(GQL_SET_COURSE_POSITION)

  const playCurrentLesson = useCallback(() => {
    if (!course) return

    PubSub.publish(PlayLessonMessage, {})
    if (isDebug) {
      console.log('Publish:', PlayLessonMessage, {})
    }
  }, [course, isDebug])

  const navigateNextLesson = useCallback(() => {
    if (!course) return

    const position = getNextPosition(course)
    navigate(
      `/${courseName}/lesson-${position.weekNum}-${position.dayNum}-${position.lessonNum}`,
    )
    // Use message to force the expand of course navigation
    // tree because the state is not changed.
    const payload = { ...position } as NavigateLessonPayload
    PubSub.publish(NavigateLessonMessage, payload)
    if (isDebug) {
      console.log('Publish:', NavigateLessonMessage, payload)
    }
  }, [course, courseName, isDebug, navigate])

  const setCoursePositionBeforeCurrentLesson = useCallback(async () => {
    if (course) {
      const position = getPrevPosition(course, { weekNum, dayNum, lessonNum })

      await setCoursePosition({
        variables: {
          payload: {
            courseId: course._id,
            position,
          },
        },
        notifyOnNetworkStatusChange: true,
      })
      setCourse({ ...course, position })
    }
  }, [course, weekNum, dayNum, lessonNum, setCourse, setCoursePosition])

  return {
    playCurrentLesson,
    navigateNextLesson,
    setCoursePositionBeforeCurrentLesson,
    isLoading: loading,
  }
}
