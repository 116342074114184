import { ApolloClient, ApolloLink } from '@apollo/client'
import cache from './cache'
import httpLink from './links/http-link'
import authLink from './links/auth-link'
import errorLink from './links/error-link'

export default new ApolloClient({
  ssrMode: typeof window === 'undefined',
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache,
})
