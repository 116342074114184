import { Navigate, useLocation } from 'react-router-dom'
import { getAccessToken } from '../../lib/auth'

interface PrivatePageProps {
  element: React.ReactNode | null
}

const PrivatePage = (props: PrivatePageProps) => {
  const { element } = props

  const location = useLocation()
  const isLoggedIn = !!getAccessToken()

  return isLoggedIn ? (
    <>{element}</>
  ) : (
    <Navigate to={`/login`} replace state={{ prevLocation: location }} />
  )
}

export default PrivatePage
