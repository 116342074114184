import { useCallback, useEffect, useMemo } from 'react'
import type { Course } from '../models'

import { useGlobalState } from '../state'
import { useLesson } from './use-lesson'
import { useBaby } from './use-baby'

export function useCourse(): [
  course: Course | null,
  setCourse: (course: React.SetStateAction<Course | null>) => void,
] {
  const [currentBaby] = useBaby()
  const { courseName } = useLesson()
  const [courses, setCourses] = useGlobalState(`courses`)

  const course = useMemo(() => {
    return !currentBaby || !courses || !courses[currentBaby._id]
      ? null
      : courses[currentBaby._id][courseName] || null
  }, [currentBaby, courseName, courses])

  useEffect(
    () => sessionStorage.setItem('courses', JSON.stringify(courses)),
    [courses],
  )

  const setCourse = useCallback(
    (course: React.SetStateAction<Course | null>) => {
      if (!currentBaby) {
        return null
      }
      setCourses((courses) => ({
        ...(courses ?? {}),
        [currentBaby._id]: {
          ...(courses ?? {})[currentBaby._id],
          [courseName]:
            typeof course == 'function'
              ? course(((courses ?? {})[currentBaby._id] ?? {})[courseName]) // Call with previous state
              : course, // Just use provided state
        },
      }))
    },
    [currentBaby, courseName, setCourses],
  )

  return [course, setCourse]
}
