import { gql } from '@apollo/client'
import type { CoursePosition } from '../../models'

export type SetCoursePositionDto = {
  courseId: string
  position: CoursePosition
}

export const GQL_SET_COURSE_POSITION = gql`
  mutation SetCoursePosition($payload: SetCoursePositionDto!) {
    setCoursePosition(payload: $payload) {
      weekNum
      dayNum
      lessonNum
    }
  }
`
