import { zip } from './arrays'

export const flagObject = <
  T extends { [key: string]: unknown },
  K extends keyof T,
>(
  o: T,
  v = false,
): { [key in K]: boolean } => {
  const keys = Object.keys(o)
  const values = Array(keys.length).fill(v)
  const entries = zip(keys, values)
  return Object.fromEntries(entries)
}
