import * as React from 'react'
import { Navbar, Button, Alignment } from '@blueprintjs/core'
import { Link, useLocation } from 'react-router-dom'
import BabySelector from '../BabySelector'

function Navigation() {
  const location = useLocation()

  return (
    <Navbar fixedToTop>
      <Navbar.Group align={Alignment.LEFT}>
        <Navbar.Heading>My Genius Baby</Navbar.Heading>
        <Navbar.Divider />
        <Link to="/">
          <Button minimal icon="home" text="Home" />
        </Link>
        <Navbar.Divider />
        <Link to="/counts">
          <Button minimal icon="document" text="I. Counts" />
        </Link>
        <Link to="/patterns">
          <Button minimal icon="document" text="II. Patterns" />
        </Link>
        <Link to="/exercises">
          <Button minimal icon="document" text="III. Exercises" />
        </Link>
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        <BabySelector></BabySelector>
        <Navbar.Divider />
        <Button minimal icon="notifications" title="Notifications"></Button>
        <Link to="/profile/babies">
          <Button minimal icon="cog" title="Profile"></Button>
        </Link>
        <Navbar.Divider />
        <Link to="/logout" state={{ prevLocation: location }}>
          <Button minimal icon="log-out" title="Log Out"></Button>
        </Link>
      </Navbar.Group>
    </Navbar>
  )
}

export default Navigation
