import { useEffect } from 'react'

import type { User } from '../models/user.model'
import { useGlobalState } from '../state'

export function useUser(): [
  user: User | null,
  setUser: (user: React.SetStateAction<User | null>) => void,
] {
  const [user, setUser] = useGlobalState('user')

  useEffect(() => sessionStorage.setItem('user', JSON.stringify(user)), [user])

  return [user, setUser]
}
