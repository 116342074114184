import { useMemo } from 'react'

import type { CourseItem, Week, Day } from '../../../models'
import { useCourse, useLesson, useDebug } from '../../../hooks'

const toFlatPosition = (weekNum: number, dayNum: number, lessonNum: number) =>
  weekNum * 100 + dayNum * 10 + lessonNum * 1

export function useCourseNav(): CourseItem {
  const { weekNum, dayNum } = useLesson()
  const [course] = useCourse()
  const isDebug = useDebug()

  const courseNav = useMemo(() => {
    if (isDebug) console.log('Build course navigation...')

    const coursePosition = toFlatPosition(
      course?.position.weekNum ?? 0,
      course?.position.dayNum ?? 0,
      course?.position.lessonNum ?? 0,
    )

    const isGroupExpanded = (week: Week, day: Day | null = null) => {
      return week.number === weekNum && (!day || day.number === dayNum)
    }

    const courseNav: CourseItem = {
      to: '',
      title: 'course', // Just dummy title
      icon: 'add', // Just dummy icon
      level: 0,
      children: [],
      isCompleted: false,
      isExpanded: true,
      position: {
        weekNum: 0,
        dayNum: 0,
        lessonNum: 0,
      },
    }

    course?.weeks.forEach((week) => {
      const weekNav: CourseItem = {
        to: `lesson-${week.number}`,
        title: week.title,
        icon: 'calendar',
        level: 1,
        children: [],
        isCompleted: true,
        isExpanded: isGroupExpanded(week),
        position: {
          weekNum: week.number,
          dayNum: 0,
          lessonNum: 0,
        },
      }

      week.days.forEach((day) => {
        const dayNav: CourseItem = {
          to: `lesson-${week.number}-${day.number}`,
          title: day.title,
          icon: 'circle',
          level: 2,
          children: [],
          isCompleted: true,
          isExpanded: isGroupExpanded(week, day),
          position: {
            weekNum: week.number,
            dayNum: day.number,
            lessonNum: 0,
          },
        }

        day.lessons.forEach((lesson) => {
          const lessonPosition = toFlatPosition(
            week.number,
            day.number,
            lesson.number,
          )

          const lessonNav: CourseItem = {
            to: `lesson-${week.number}-${day.number}-${lesson.number}`,
            title: lesson.time,
            icon: 'time',
            level: 3,
            children: [],
            isCompleted: lessonPosition <= coursePosition,
            isExpanded: false,
            position: {
              weekNum: week.number,
              dayNum: day.number,
              lessonNum: lesson.number,
            },
          }

          dayNav.isCompleted = dayNav.isCompleted && lessonNav.isCompleted
          dayNav.children.push(lessonNav)
        })

        weekNav.isCompleted = weekNav.isCompleted && dayNav.isCompleted
        weekNav.children.push(dayNav)
      })

      courseNav.children.push(weekNav)
    })

    return courseNav

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course]) // Rebuild navigation when course is changed only.

  return courseNav
}
