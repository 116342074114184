import { ApolloLink } from '@apollo/client'
import { getAccessToken } from '../../auth'

const authLink = new ApolloLink((operation, forward) => {
  const token = getAccessToken()
  operation.setContext(() => ({
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
      'Apollo-Require-Preflight': true,
    },
  }))
  return forward(operation)
})

export default authLink
