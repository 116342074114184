import { gql } from '@apollo/client'

export type DeleteBabyDto = {
  _id: string
}

export const GQL_DELETE_BABY = gql`
  mutation DeleteBaby($payload: DeleteBabyDto!) {
    deleteBaby(payload: $payload) {
      _id
      firstName
      dateOfBirth
    }
  }
`
