import * as React from 'react'
import { Button, Card, FormGroup } from '@blueprintjs/core'
import type { Baby } from '../../models/baby.model'
import { useQuery } from '@apollo/client'
import { GQL_GET_BABIES } from '../../api'
import Toaster from '../Toaster'
import Spinner from '../Spinner'
import { useBabies, useBaby } from '../../hooks'
import Flex from '@react-css/flex'
import { intervalToDuration, parse } from 'date-fns'
import CreateBabyDialog from './dialogs/CreateBabyDialog'
import DeleteBabyDialog from './dialogs/DeleteBabyDialog'

function getAge(dob: string) {
  const birthDate = parse(dob, 'yyyy-MM-dd', new Date())
  const { years, months } = intervalToDuration({
    start: birthDate,
    end: new Date(),
  })
  const age = (
    (years ? `${years} year${years > 1 ? 's' : ''} ` : '') +
    (months ? `${months} month${months > 1 ? 's' : ''} ` : '')
  ).trim()
  return age ? age : 'newborn'
}

const BabyList = () => {
  const [currentBaby, setCurrentBaby] = useBaby()
  const [babies, setBabies] = useBabies()
  const [selectedBaby, setSelectedBaby] = React.useState<Baby | null>(null)
  const { data, loading, error, refetch } = useQuery<{
    babies: Baby[]
  }>(GQL_GET_BABIES)
  const [createDialogIsOpen, setCreateDialogIsOpen] = React.useState(false)
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = React.useState(false)

  React.useEffect(() => {
    if (data === undefined) return

    const newBabies = data.babies ?? []
    setBabies(newBabies)

    const newCurrentBaby =
      newBabies.find((b) => b._id === currentBaby?._id) || null
    setCurrentBaby(newCurrentBaby)
  }, [data?.babies])

  if (loading) return <Spinner />
  if (error) {
    Toaster.show({ intent: 'danger', message: error })
    return null
  }

  return (
    <>
      <div>
        <h2 style={{ marginTop: 0 }}>My Babies</h2>
        <FormGroup>
          {babies.length ? (
            babies.map((baby: Baby) => (
              <Card key={baby._id} style={{ marginBottom: '1rem' }}>
                <Flex row justifySpaceBetween>
                  <div>
                    <strong style={{ fontSize: '1.4rem' }}>
                      {baby.firstName}
                    </strong>{' '}
                    <sub>{getAge(baby.dateOfBirth)}</sub>
                  </div>
                  <Button
                    title="Delete"
                    intent="danger"
                    icon="trash"
                    onClick={() => {
                      setSelectedBaby(baby)
                      setDeleteDialogIsOpen(true)
                    }}
                  ></Button>
                </Flex>
              </Card>
            ))
          ) : (
            <span>There are still no babies</span>
          )}
        </FormGroup>
        <Button icon="plus" onClick={() => setCreateDialogIsOpen(true)}>
          Give Birth
        </Button>
      </div>
      <CreateBabyDialog
        isOpen={createDialogIsOpen}
        onClose={() => setCreateDialogIsOpen(false)}
        onCreate={() => {
          refetch()
        }}
      />
      <DeleteBabyDialog
        isOpen={deleteDialogIsOpen}
        baby={selectedBaby}
        onClose={() => setDeleteDialogIsOpen(false)}
        onDelete={() => {
          refetch()
        }}
      />
    </>
  )
}

export default BabyList
