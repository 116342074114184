/* eslint-disable @typescript-eslint/no-non-null-assertion */

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Icon } from '@blueprintjs/core'
import { useMutation } from '@apollo/client'

import type { CoursePosition } from '../../models'
import {
  useCourse,
  useCourseNavigation,
  useLesson,
  useDebug,
  useSpeechSynth,
} from '../../hooks'
import MathCard from '../MathCard'
import {
  CenterButton,
  CenterButtons,
  ControlPanel,
  DebugPanel,
  LeftButton,
  LessonWrapper,
  NextButton,
  RightButton,
  StartButton,
} from './styled'
import { GQL_SET_COURSE_POSITION, SetCoursePositionDto } from '../../api'
import { useFullScreen } from '../../hooks/use-full-screen'
import { PlayLessonMessage } from '../../messages'
import { useCourseCards } from '../../hooks/use-course-cards'
import Spinner from '../Spinner'
import Flex from '@react-css/flex'
import { useDebouncedCallback } from '../../hooks/use-debounced-callback'

const NEXT_LESSON_TIMEOUT = 250

const toFlatPosition = (position: CoursePosition) =>
  position.weekNum * 100 + position.dayNum * 10 + position.lessonNum * 1

const Lesson = () => {
  const { weekNum, dayNum, lessonNum } = useLesson()
  const [course, setCourse] = useCourse()
  const [courseCards] = useCourseCards()
  const [cardIndex, setCardIndex] = useState(0)
  const [isStarted, setStarted] = useState(false)
  const [isCompleted, setCompleted] = useState(false)
  const lessonEl = useRef<HTMLDivElement | null>(null)
  const { navigateNextLesson } = useCourseNavigation()
  const { speak } = useSpeechSynth()
  const [, setFullScreen] = useFullScreen()
  const isDebug = useDebug()

  const [setCoursePosition, { loading }] = useMutation<
    void,
    { payload: SetCoursePositionDto }
  >(GQL_SET_COURSE_POSITION)

  const startButtonEl = useRef<HTMLButtonElement>(null)

  const coursePosition = useMemo(() => {
    return (
      (course?.position?.weekNum ?? 0) * 100 +
      (course?.position?.dayNum ?? 0) * 10 +
      (course?.position?.lessonNum ?? 0) * 1
    )
  }, [course])

  const isLessonCompleted = useCallback(
    (weekNum: number, dayNum: number, lessonNum: number) => {
      return weekNum * 100 + dayNum * 10 + lessonNum * 1 <= coursePosition
    },
    [coursePosition],
  )

  const startLesson = useCallback(() => {
    setStarted(true)
    setCardIndex(0)
    process.nextTick(() => {
      lessonEl.current?.focus()
    })
    setFullScreen(true)
  }, [setFullScreen])

  const stopLesson = useCallback(() => {
    setStarted(false)
    setCardIndex(0)
    process.nextTick(() => {
      startButtonEl.current?.focus()
    })
    setFullScreen(false)
  }, [setFullScreen])

  useEffect(() => {
    const subsription = PubSub.subscribe(
      PlayLessonMessage,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (message, payload) => {
        startLesson()
      },
    )
    return () => {
      PubSub.unsubscribe(subsription)
    }
  }, [])

  const cards = useMemo(() => {
    setCompleted(isLessonCompleted(weekNum, dayNum, lessonNum))

    return (
      course?.weeks[weekNum - 1]?.days[dayNum - 1]?.lessons[lessonNum - 1]
        ?.cards || []
    )
  }, [course?.weeks, dayNum, isLessonCompleted, lessonNum, weekNum])

  const card = useMemo((): string => {
    return cardIndex <= cards.length - 1 ? cards[cardIndex] : cards[0]
  }, [cards, cardIndex])

  const points = useMemo(() => {
    return courseCards ? courseCards.cardPoints[card] || [] : []
  }, [courseCards, card])

  const prevCard = useDebouncedCallback(
    () => {
      if (isStarted) {
        setCardIndex((cardIndex) => Math.max(0, cardIndex - 1))
      }
    },
    [isStarted],
    NEXT_LESSON_TIMEOUT,
  )

  const repeatCard = useDebouncedCallback(
    () => {
      if (isStarted) {
        console.log('speak2')
        speak(card)
      }
    },
    [card, isStarted],
    NEXT_LESSON_TIMEOUT,
  )

  const nextCard = useDebouncedCallback(
    () => {
      if (isStarted) {
        setCardIndex((cardIndex) => Math.min(cards.length, cardIndex + 1))
      }
    },
    [cards.length, isStarted],
    NEXT_LESSON_TIMEOUT,
  )

  const isCoursePositionChanged = useCallback(
    (newPosition) => {
      return toFlatPosition(newPosition) > toFlatPosition(course!.position)
    },
    [course],
  )

  const completeLesson = useCallback(async () => {
    const coursePosition: CoursePosition = {
      weekNum: weekNum,
      dayNum: dayNum,
      lessonNum: lessonNum,
    }
    if (isCoursePositionChanged(coursePosition)) {
      if (isDebug) console.log('Set course position...')

      await setCoursePosition({
        variables: {
          payload: {
            courseId: course!._id,
            position: coursePosition,
          },
        },
        notifyOnNetworkStatusChange: true,
      })
      setCourse({ ...course!, position: coursePosition })
    }
  }, [
    weekNum,
    dayNum,
    lessonNum,
    isCoursePositionChanged,
    isDebug,
    setCoursePosition,
    course,
    setCourse,
  ])

  useEffect(() => {
    if (!isStarted) {
      return
    }

    if (cardIndex === cards.length) {
      stopLesson()

      setCompleted(true)
      completeLesson()
    } else {
      console.log('speak1')
      speak(card)
    }
  }, [
    card,
    cardIndex,
    cards.length,
    completeLesson,
    isStarted,
    speak,
    stopLesson,
  ])

  const navigateCard = useCallback(
    (e: React.KeyboardEvent) => {
      e.stopPropagation()
      switch (e.code) {
        case 'Escape':
          stopLesson()
          break
        case 'ArrowLeft':
          prevCard()
          break
        case 'ArrowRight':
          nextCard()
          break
        case 'Enter':
          repeatCard()
          break
      }
    },
    [nextCard, prevCard, repeatCard, stopLesson],
  )

  return (
    <LessonWrapper onKeyDown={navigateCard} tabIndex={0} ref={lessonEl}>
      <MathCard card={card} cardIndex={cardIndex} points={points} />
      <ControlPanel>
        {isDebug && (
          <DebugPanel>
            <div>
              Lesson: lesson-{weekNum}-{dayNum}-{lessonNum}
            </div>
            <div>Cards: {JSON.stringify(cards)}</div>
            <div>Card: {JSON.stringify(card)}</div>
          </DebugPanel>
        )}
        <LeftButton onClick={prevCard}>{isDebug && 'Prev'}</LeftButton>
        <CenterButton onClick={repeatCard}>
          {isDebug && 'Repeat'}
          {course && !isStarted && (
            <CenterButtons>
              {points.length === 0 && (
                <Flex column>
                  <Spinner marginBottom={1} />
                  <div>Lesson is preparing...</div>
                </Flex>
              )}
              {points.length > 0 && (
                <>
                  <StartButton elementRef={startButtonEl} onClick={startLesson}>
                    <Icon icon="play" />
                  </StartButton>
                  {isCompleted && (
                    <NextButton onClick={navigateNextLesson}>
                      <Icon icon="fast-forward" />
                    </NextButton>
                  )}
                </>
              )}
            </CenterButtons>
          )}
        </CenterButton>
        <RightButton onClick={nextCard}>{isDebug && 'Next'}</RightButton>
      </ControlPanel>
    </LessonWrapper>
  )
}

export default Lesson
