import { useEffect } from 'react'
import type { LessonSettings } from '../models'
import { useGlobalState } from '../state'

export const useLessonSettings = (): [
  lessonSettings: LessonSettings,
  setLessonSettings: (
    lessonSettings: React.SetStateAction<LessonSettings>,
  ) => void,
] => {
  const [lessonSettings, setLessonSettings] = useGlobalState('lessonSettings')

  useEffect(
    () =>
      sessionStorage.setItem('lessonSettings', JSON.stringify(lessonSettings)),
    [lessonSettings],
  )

  return [lessonSettings, setLessonSettings]
}
