import styled from '@emotion/styled'
import { Spinner } from '@blueprintjs/core'

type SpinnerWrapperProps = {
  marginTop?: number
  marginRight?: number
  marginBottom?: number
  marginLeft?: number
}

export const SpinnerWrapper = styled(Spinner)<SpinnerWrapperProps>`
  height: 100%;
  margin-top: ${(props) => props.marginTop ?? '0'}em;
  margin-right: ${(props) => props.marginRight ?? '0'}em;
  margin-bottom: ${(props) => props.marginBottom ?? '0'}em;
  margin-left: ${(props) => props.marginLeft ?? '0'}em;
`
