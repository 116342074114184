import React from 'react'
import { NavLevel, NavIcon, NavLabel } from './styled'
import type { CourseItem } from './types'

type CourseTitleProps = {
  item: CourseItem
}

const CourseTitle = (props: CourseTitleProps) => {
  const { item } = props

  return (
    <>
      <NavLevel level={(item.level - 1) * 2} />
      <NavIcon icon={item.icon} />
      <NavLabel>{item.title}</NavLabel>
    </>
  )
}

export default CourseTitle
