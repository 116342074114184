import { useMemo } from 'react'
import { UserStorage } from '../lib/user-storage'
import { useGlobalState } from '../state'

export function useUserStorage(): UserStorage | null {
  const [user, setUser] = useGlobalState('user')

  const userStorage = useMemo(() => {
    return user ? new UserStorage(user._id) : null
  }, [user])

  return userStorage
}
