import { useParams } from 'react-router-dom'
import { useMemo } from 'react'
import type { CourseName } from '../types'

export function useLesson(): {
  courseName: CourseName
  weekNum: number
  dayNum: number
  lessonNum: number
} {
  const params = useParams()

  const courseName = useMemo(() => params.course as CourseName, [params.course])
  const weekNum = useMemo(() => parseInt(params.week ?? '0', 10), [params.week])
  const dayNum = useMemo(() => parseInt(params.day ?? '0', 10), [params.day])
  const lessonNum = useMemo(
    () => parseInt(params.lesson ?? '0', 10),
    [params.lesson],
  )

  return { courseName, weekNum, dayNum, lessonNum }
}
