import { useCallback, useState } from 'react'
import ModalDialog from '../ModalDialog/ModalDialog'

type ConfirmationDialogProps = {
  isOpen: boolean
  header: React.ReactNode | (() => React.ReactNode)
  body: React.ReactNode | (() => React.ReactNode)
  onClose: () => void
  onConfirm: () => Promise<void>
}

function ConfirmationDialog(props: ConfirmationDialogProps) {
  const { isOpen, header, body, onClose, onConfirm } = props

  const [isLoading, setLoading] = useState(false)

  const handleSubmit = useCallback(async () => {
    setLoading(true)
    try {
      await onConfirm()
      onClose()
    } finally {
      setLoading(false)
    }
  }, [onClose, onConfirm])

  return (
    <ModalDialog
      isOpen={isOpen}
      isLoading={isLoading}
      header={header}
      body={body}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  )
}

export default ConfirmationDialog
