export const getAccessToken = () => {
  return sessionStorage.getItem('accessToken')
}

export const getRefreshToken = () => {
  return sessionStorage.getItem('refreshToken')
}

export const setAuthData = (accessToken: string, refreshToken: string) => {
  sessionStorage.setItem('accessToken', accessToken)
  sessionStorage.setItem('refreshToken', refreshToken)
}

export const removeAuthData = () => {
  sessionStorage.removeItem('accessToken')
  sessionStorage.removeItem('refreshToken')
}

export const removeSessionData = () => {
  sessionStorage.removeItem('user')
  sessionStorage.removeItem('courses')
  sessionStorage.removeItem('cards')
  sessionStorage.removeItem('lessonSettings')
  sessionStorage.removeItem('speechSettings')
}
