import { gql } from '@apollo/client'

export type CreateBabyDto = {
  firstName: string
  dateOfBirth: string
}

export const GQL_CREATE_BABY = gql`
  mutation CreateBaby($payload: CreateBabyDto!) {
    createBaby(payload: $payload) {
      _id
      firstName
      dateOfBirth
    }
  }
`
