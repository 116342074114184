import { useCallback, useEffect, useMemo } from 'react'

import { useGlobalState } from '../state'
import type { CourseCards } from '../types'
import { useBaby } from './use-baby'
import { useLesson } from './use-lesson'
import { useUserStorage } from './use-user-storage'

export function useCourseCards(): [
  courseCards: CourseCards | null,
  setCourseCards: (
    courseCards: React.SetStateAction<CourseCards | null>,
  ) => void,
] {
  const [currentBaby] = useBaby()
  const { courseName } = useLesson()
  const [_courseCards, _setCourseCards] = useGlobalState('courseCards')
  const userStorage = useUserStorage()

  const courseCards = useMemo(() => {
    return !currentBaby || !_courseCards || !_courseCards[currentBaby._id]
      ? null
      : _courseCards[currentBaby._id][courseName] ?? null
  }, [currentBaby, courseName, _courseCards])

  useEffect(() => {
    if (!_courseCards) return
    if (!currentBaby) return

    const courseCards = (_courseCards[currentBaby._id] ?? {})[courseName]
    if (!courseCards) return

    userStorage?.setCourseCards(currentBaby._id, courseName, courseCards)
  }, [_courseCards, courseName, currentBaby, userStorage])

  const setCourseCards = useCallback(
    (courseCards: React.SetStateAction<CourseCards | null>) => {
      if (!currentBaby) return null
      if (!courseName) return null

      _setCourseCards((_courseCards) => {
        const newCourseCards =
          typeof courseCards == 'function'
            ? courseCards(
                ((_courseCards ?? {})[currentBaby._id] ?? {})[courseName] ??
                  null,
              ) // Call with previous state
            : courseCards // Just use provided state
        return {
          ...(_courseCards ?? {}),
          [currentBaby._id]: {
            ...(_courseCards ?? {})[currentBaby._id],
            [courseName]: {
              cardPoints: {
                ...((_courseCards ?? {})[currentBaby._id] ?? {})[courseName]
                  ?.cardPoints,
                ...newCourseCards?.cardPoints,
              },
              isPrepared: newCourseCards?.isPrepared,
            },
          },
        }
      })
    },
    [currentBaby, courseName, _setCourseCards],
  )

  return [courseCards, setCourseCards]
}
