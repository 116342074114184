import Dexie, { Table } from 'dexie'

import type { BabyCourseCards, CourseCards, CourseName } from '../../types'

export interface CourseCardsTable {
  id: string
  babyId: string
  courseName: CourseName
  courseCards: CourseCards
}

export class UserStorage extends Dexie {
  courseCards!: Table<CourseCardsTable>

  constructor(userId: string) {
    super(userId)
    this.version(1).stores({
      courseCards: 'id, babyId, courseName, courseCards',
    })
  }

  deleteAllCourseCards() {
    return this.courseCards.clear()
  }

  deleteCourseCardsForBaby(babyId: string) {
    return this.courseCards.where('babyId').equals(babyId).delete()
  }

  async getCourseCards(): Promise<BabyCourseCards | null> {
    let _courseCards: BabyCourseCards | null = null

    const courseCardsRecs = await this.courseCards.toArray()
    if (courseCardsRecs.length) {
      _courseCards = {}
      for (const courseCardRec of courseCardsRecs) {
        if (!_courseCards[courseCardRec.babyId]) {
          _courseCards[courseCardRec.babyId] = {}
        }
        _courseCards[courseCardRec.babyId][courseCardRec.courseName] =
          courseCardRec.courseCards
      }
    }

    return _courseCards
  }

  setCourseCards(
    babyId: string,
    courseName: CourseName,
    courseCards: CourseCards,
  ) {
    return this.courseCards.put({
      id: `${babyId}.${courseName}`,
      babyId,
      courseName,
      courseCards,
    })
  }
}
