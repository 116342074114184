import { NavLink } from 'react-router-dom'
import { ChevronRightIcon } from '@heroicons/react/outline'
import { Colors, Icon, Divider as DividerBP } from '@blueprintjs/core'
import styled from '@emotion/styled'

interface NavItemProps {
  active: number
  completed: number
}

export const NavItem = styled(NavLink)<NavItemProps>`
  padding: 0.3rem 1rem;
  height: 2.5rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;

  background-color: ${(props) => (props.active ? Colors.BLUE1 : 'inherit')};
  color: ${Colors.WHITE};
  text-decoration: ${(props) => (props.completed ? 'line-through' : 'none')};

  &:hover {
    background-color: ${(props) =>
      props.active ? Colors.BLUE1 : Colors.BLUE3};
    color: ${Colors.WHITE};
    text-decoration: ${(props) => (props.completed ? 'line-through' : 'none')};
  }
`

interface NavGroupsProps {
  completed: number
}

export const NavGroup = styled.div<NavGroupsProps>`
  padding: 0.3rem 1rem;
  height: 2.5rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;

  background: transparent;
  text-decoration: ${(props) => (props.completed ? 'line-through' : 'none')};

  &:hover {
    background-color: ${Colors.BLUE3};
    text-decoration: ${(props) => (props.completed ? 'line-through' : 'none')};
  }
`

type NavLevelProps = {
  level: number
}

export const NavLevel = styled.div<NavLevelProps>`
  margin-left: ${({ level }) => `${level}rem}`};
`

export const NavIcon = styled(Icon)`
  color: ${Colors.BLUE4} !important;
  margin-right: 1rem;
`

export const NavLabel = styled.span`
  color: ${Colors.WHITE};
  font-size: 1rem;
`

export const NavChevron = styled(ChevronRightIcon)`
  color: ${Colors.LIGHT_GRAY5};
  width: 1.2rem;
  height: 1.2rem;
  margin-left: auto;
  transition: all 0.25s;

  &.expanded {
    transform: rotate(90deg);
  }
`

export const NoCourse = styled.div`
  padding: 1rem;

  :after {
    content: 'No course';
  }
`

export const CourseNavbarActionsWrapper = styled.div`
  padding-left: 1rem;
  margin-bottom: 0.8rem;
`

export const Divider = styled(DividerBP)`
  border-bottom-color: silver;
  border-right-color: silver;
`
