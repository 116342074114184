import * as React from 'react'
import type { ThemeKey } from '../../models/theme.model'
import { SidebarWrapper, SidebarHead, SidebarBody } from './styled'

type SidebarProps = {
  title?: string
  children?: React.ReactNode
  themeKey?: ThemeKey
  hidden?: boolean
}

function Sidebar(props: SidebarProps) {
  const { title, children, themeKey, hidden } = props

  return (
    <SidebarWrapper themeKey={themeKey} hidden={hidden}>
      {title && <SidebarHead>{title}</SidebarHead>}
      <SidebarBody>{children}</SidebarBody>
    </SidebarWrapper>
  )
}

export default Sidebar
