import { createGlobalState } from 'react-hooks-global-state'
import { UserStorage } from './lib/user-storage'
import type { LessonSettings, SpeechSettings } from './models'
import type { Baby } from './models/baby.model'
import type { User } from './models/user.model'
import type { BabyCourses, BabyCourseCards } from './types'

type GlobalState = {
  user: User | null
  baby: Baby | null
  babies: Baby[]
  courses: BabyCourses | null
  courseCards: BabyCourseCards | null
  speechSettings: SpeechSettings
  lessonSettings: LessonSettings
  isFullScreen: boolean
}

const getUser = (): User | null => {
  const userJson = sessionStorage.getItem('user')
  const user = userJson ? JSON.parse(userJson) : null
  return user
}

const getBaby = (): Baby | null => {
  const babies = getBabies()
  const currentBabyId = localStorage.getItem('currentBabyId')
  const baby = babies.find((b: Baby) => b._id == currentBabyId) || null
  return baby
}

const getBabies = (): Baby[] => {
  const babiesJson = sessionStorage.getItem('babies')
  const babies = babiesJson ? JSON.parse(babiesJson) : []
  return babies
}

const getCourses = (): BabyCourses => {
  const coursesJson = sessionStorage.getItem('courses')
  const courses = coursesJson ? JSON.parse(coursesJson) : null
  return courses
}

const getSpeechSettings = (): SpeechSettings => {
  const speechSettingsJson = sessionStorage.getItem(`speechSettings`)
  const speechSettings = speechSettingsJson
    ? JSON.parse(speechSettingsJson)
    : {
        voiceName: 'Google US English',
        pitch: 1.0,
        rate: 1.0,
      }

  return speechSettings
}

const getLessonSettings = (): LessonSettings => {
  const lessonSettingsJson = sessionStorage.getItem(`lessonSettings`)
  const lessonSettings = lessonSettingsJson
    ? JSON.parse(lessonSettingsJson)
    : {
        useBrowserFullScreen: true,
      }

  return lessonSettings
}

const getState = (): GlobalState => ({
  user: getUser(),
  baby: getBaby(),
  babies: getBabies(),
  courses: getCourses(),
  // Will be loaded asynchronously on the app startup.
  // See App.tsx file for details.
  courseCards: null,
  speechSettings: getSpeechSettings(),
  lessonSettings: getLessonSettings(),
  isFullScreen: false,
})

export const { useGlobalState } = createGlobalState<GlobalState>(getState())
