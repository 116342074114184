import { gql } from '@apollo/client'

export const GQL_GET_USER_INFO = gql`
  query GetUserInfo {
    userInfo {
      user {
        _id
        email
        firstName
        lastName
      }
      profile {
        speechSettings {
          voiceName
          pitch
          rate
        }
        lessonSettings {
          useBrowserFullScreen
        }
      }
      babies {
        _id
        firstName
        dateOfBirth
      }
    }
  }
`
