import { useEffect } from 'react'
import type { SpeechSettings } from '../models'
import { useGlobalState } from '../state'

export const useSpeechSettings = (): [
  speechSettings: SpeechSettings,
  setSpeechSettings: (
    speechSettings: React.SetStateAction<SpeechSettings>,
  ) => void,
] => {
  const [speechSettings, setSpeechSettings] = useGlobalState('speechSettings')

  useEffect(
    () =>
      sessionStorage.setItem('speechSettings', JSON.stringify(speechSettings)),
    [speechSettings],
  )

  return [speechSettings, setSpeechSettings]
}
