import * as React from 'react'
import Body from '../Body'
import Footer from '../Footer'
import Header from '../Header'

function Layout() {
  return (
    <>
      <Header />
      <Body />
      <Footer />
    </>
  )
}

export default Layout
