import { gql } from '@apollo/client'

export type GetOrCreateCourseDto = {
  babyId: string
  courseName: string
}

export const GQL_GET_OR_CREATE_COURSE = gql`
  mutation GetOrCreateCourse($payload: GetOrCreateCourseDto!) {
    getOrCreateCourse(payload: $payload) {
      _id
      title
      weeks {
        number
        title
        days {
          number
          title
          lessons {
            time
            number
            title
            cards
          }
        }
      }
      position {
        weekNum
        dayNum
        lessonNum
      }
      cards {
        minCard
        maxCard
      }
    }
  }
`
