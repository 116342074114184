import styled from '@emotion/styled'
import type { Theme, ThemeKey } from '../../models/theme.model'

type ContentWrapperProps = {
  theme?: Theme
  themeKey?: ThemeKey
  padding?: number
}

export const ContentWrapper = styled.div<ContentWrapperProps>`
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    props.theme[props.themeKey || 'none']?.backgroundColor || 'inherit'};
  color: ${(props) =>
    props.theme[props.themeKey || 'none']?.color || 'inherit'};
  padding: ${(props) => (props.padding ? `${props.padding}rem` : '0')};
`
