import { Outlet } from 'react-router-dom'
import Content from '../../components/Content'
import ProfileNavbar from '../../components/ProfileNavbar'
import Sidebar from '../../components/Sidebar'

function ProfilePage() {
  return (
    <>
      <Sidebar title="Profile">
        <ProfileNavbar />
      </Sidebar>
      <Content padding={1}>
        <Outlet />
      </Content>
    </>
  )
}

export default ProfilePage
