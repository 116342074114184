import { useCallback, useState } from 'react'
import { FormGroup, InputGroup } from '@blueprintjs/core'
import type { Baby } from '../../../models/baby.model'
import { useMutation } from '@apollo/client'
import { CreateBabyDto, GQL_CREATE_BABY } from '../../../api/baby/create-baby'
import { format } from 'date-fns'
import ModalDialog from '../../ModalDialog/ModalDialog'

type BabyDialogProps = {
  isOpen: boolean
  onClose: () => void
  onCreate: (baby: Baby) => void
}

function CreateBabyDialog(props: BabyDialogProps) {
  const { isOpen, onClose, onCreate } = props

  const [firstName, setFirstName] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState(
    format(new Date(), 'yyyy-MM-dd'),
  )

  const [createBaby, { loading }] = useMutation<
    { createBaby: Baby },
    { payload: CreateBabyDto }
  >(GQL_CREATE_BABY)

  const handleSubmit = useCallback(async () => {
    const res = await createBaby({
      variables: { payload: { firstName, dateOfBirth } },
      notifyOnNetworkStatusChange: true,
    })
    const newBaby = res.data?.createBaby || null
    onClose()

    if (newBaby) {
      onCreate(newBaby)

      setFirstName('')
      setDateOfBirth(format(new Date(), 'yyyy-MM-dd'))
    }
  }, [createBaby, firstName, dateOfBirth, onClose, onCreate])

  return (
    <ModalDialog
      isOpen={isOpen}
      isLoading={loading}
      header={'Give Birth To A Baby'}
      body={() => (
        <>
          <FormGroup label="First Name">
            <InputGroup
              type="text"
              value={firstName}
              autoFocus
              onChange={(e) => setFirstName(e.target.value)}
            ></InputGroup>
          </FormGroup>
          <FormGroup label="Date Of Birth">
            <InputGroup
              type="date"
              value={dateOfBirth}
              onChange={(e) => setDateOfBirth(e.target.value)}
            ></InputGroup>
          </FormGroup>
        </>
      )}
      onSubmit={handleSubmit}
      onClose={onClose}
    />
  )
}

export default CreateBabyDialog
