import { useEffect } from 'react'
import type { Baby } from '../models/baby.model'

import { useGlobalState } from '../state'

export function useBaby(): [
  baby: Baby | null,
  setBaby: (baby: React.SetStateAction<Baby | null>) => void,
] {
  const [baby, setBaby] = useGlobalState('baby')

  useEffect(
    () => localStorage.setItem('currentBabyId', baby ? baby._id : ''),
    [baby],
  )

  return [baby, setBaby]
}
