import * as React from 'react'
import { ItemRenderer, Select } from '@blueprintjs/select'
import { Button, Label, MenuItem } from '@blueprintjs/core'
import { Flex } from '@react-css/flex'
import { useBabies, useBaby } from '../../hooks'
import type { Baby } from '../../models/baby.model'
import styled from '@emotion/styled'

const FlexWrapper = styled(Flex)`
  margin-top: 0.3rem;
`
const BabySelect = Select.ofType<Baby>()

function BabySelector() {
  const [currentBaby, setCurrentBaby] = useBaby()
  const [babies] = useBabies()

  const renderBaby: ItemRenderer<Baby> = (baby, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null
    }
    return (
      <MenuItem
        key={baby._id}
        active={baby._id == currentBaby?._id}
        disabled={modifiers.disabled}
        onClick={handleClick}
        text={`${baby.firstName}`}
      />
    )
  }

  return (
    <FlexWrapper row alignItemsBaseline>
      <Label>Baby:</Label>&nbsp;
      <BabySelect
        items={babies}
        itemRenderer={renderBaby}
        onItemSelect={setCurrentBaby}
        filterable={false}
        noResults={<MenuItem disabled={true} text="No babies" />}
      >
        <Button
          text={currentBaby ? currentBaby.firstName : 'No babies'}
          rightIcon="double-caret-vertical"
        />
      </BabySelect>
    </FlexWrapper>
  )
}

export default BabySelector
