import styled from '@emotion/styled'
import * as React from 'react'
import { useFullScreen } from '../../hooks'

const FooterWrapper = styled.footer`
  height: 0;
  bottom: 0;
`

function Footer() {
  const [isFullScreen] = useFullScreen()

  return <FooterWrapper hidden={isFullScreen}></FooterWrapper>
}

export default Footer
