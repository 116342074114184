import { useMutation } from '@apollo/client'
import { Card, Elevation, FormGroup, InputGroup } from '@blueprintjs/core'
import Flex from '@react-css/flex'
import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'

import { GQL_LOGIN } from '../../api'
import { useAuth, useNavigation } from '../../hooks'
import { getAccessToken } from '../../lib/auth'
import {
  Form,
  GoogleLoginButton,
  Heading,
  LocalLoginButton,
  Wrapper,
} from './styled'

const LoginPage = () => {
  const [navigateToApp, navigateToLogout] = useNavigation()
  const [authenticate, { loading: isUserInfoLoading }] = useAuth()
  const [cookies, setCookie, removeCookie] = useCookies([
    'accessToken',
    'refreshToken',
  ])

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [login, { loading: isLoginLoading }] = useMutation<
    { login: { accessToken: string; refreshToken: string } },
    { email: string; password: string }
  >(GQL_LOGIN, { notifyOnNetworkStatusChange: true })

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()

      const res = await login({ variables: { email, password } })
      if (!res.data) return

      const { accessToken, refreshToken } = res.data.login
      const isSuccess = await authenticate(accessToken, refreshToken)
      if (!isSuccess) return

      navigateToApp()
    },
    [email, password],
  )

  const loginWithGoogle = useCallback(() => {
    window.location.replace(process.env.REACT_APP_API_URL + '/auth/google')
  }, [])

  const isLoading = useMemo(() => {
    return isLoginLoading || isUserInfoLoading
  }, [isLoginLoading, isUserInfoLoading])

  useEffect(() => {
    const accessToken = cookies.accessToken
    const refreshToken = cookies.refreshToken

    removeCookie('accessToken')
    removeCookie('refreshToken')

    if (accessToken && refreshToken) {
      // If it is automatic login after external authentication (Google etc.)
      authenticate(accessToken, refreshToken).then((result) => {
        result ? navigateToApp() : navigateToLogout()
      })
    } else {
      // If we logged in then just return on previous or home page.
      if (getAccessToken()) {
        navigateToApp()
        return
      }
    }
  }, [])

  if (getAccessToken()) {
    // Do not show login form if a user is authenticated.
    return null
  }

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit}>
        <Card elevation={Elevation.TWO}>
          <Heading>My Genius Baby</Heading>
          <FormGroup label="Email">
            <InputGroup
              type="text"
              name="email"
              value={email}
              placeholder="email"
              autoFocus
              large
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>
          <FormGroup label="Password">
            <InputGroup
              type="password"
              name="password"
              value={password}
              placeholder="password"
              large
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Flex justifySpaceBetween>
              <GoogleLoginButton disabled={isLoading} onClick={loginWithGoogle}>
                Sign in with Google
              </GoogleLoginButton>
              <LocalLoginButton
                type="submit"
                intent="primary"
                icon={isLoading ? 'refresh' : 'log-in'}
                disabled={isLoading}
              >
                Sign in
              </LocalLoginButton>
            </Flex>
          </FormGroup>
        </Card>
      </Form>
    </Wrapper>
  )
}

export default LoginPage
