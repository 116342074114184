import * as React from 'react'
import Layout from '../Layout'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import LoginPage from '../../pages/LoginPage'
import LogoutPage from '../../pages/LogoutPage'
import HomePage from '../../pages/HomePage'
import CountsPage from '../../pages/CountsPage'
import PrivatePage from '../../pages/PrivatePage'
import ProfilePage from '../../pages/ProfilePage'
import PatternsPage from '../../pages/PatternsPage'
import Lesson from '../Lesson'
import BabyList from '../BabyList'
import SpeachSynthSettings from '../SpeachSynthSettings'
import LessonSettings from '../LessonSettings'

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/logout" element={<LogoutPage />} />

        <Route path="/" element={<Layout />}>
          <Route index element={<PrivatePage element={<HomePage />} />} />
          <Route
            path=":course"
            element={<PrivatePage element={<CountsPage />} />}
          >
            <Route path="lesson-:week-:day-:lesson" element={<Lesson />} />
          </Route>
          <Route
            path="patterns"
            element={<PrivatePage element={<PatternsPage />} />}
          />
          <Route path="exercises">Exercises</Route>
          <Route
            path="profile"
            element={<PrivatePage element={<ProfilePage />} />}
          >
            <Route path="babies" element={<BabyList />} />
            <Route path="speach-synth" element={<SpeachSynthSettings />} />
            <Route path="lesson" element={<LessonSettings />} />
            <Route
              path=""
              element={<>Please, select a profile category to edit...</>}
            />
          </Route>
          {/* <Route path="*" element={NoMatch}></Route> */}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default AppRoutes
