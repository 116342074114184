import styled from '@emotion/styled'
import type { Theme, ThemeKey } from '../../models/theme.model'

type SidebarWrapperProps = {
  theme?: Theme
  themeKey?: ThemeKey
}

export const SidebarWrapper = styled.div<SidebarWrapperProps>`
  min-width: 16rem;
  overflow-y: scroll;
  background-color: ${(props) =>
    props.theme[props.themeKey || 'none']?.backgroundColor || 'inherit'};
  color: ${(props) =>
    props.theme[props.themeKey || 'none']?.color || 'inherit'};
`

export const SidebarHead = styled.h2`
  padding-left: 1rem;
`

export const SidebarBody = styled.div``
