import { Vector } from 'p5'
import type P5 from 'p5'
import theme from '../../theme'

// Spring simulation constants
const M = 0.85 // Mass
const K = 0.2 // Spring constant
const D = 0.85 // Damping

// Repulsion simulation constants
const P = 5.0 // Power of repulsion
const PS = 1.5 // Power scope
const PT = 2.5 // Power threshold
const S = 5.0 // Speed limit
const MAX_D = 0.5 // Max distance to move bubble
const MIN_D = 0.1 // Min distance to move bubble

// Theme
const bubbleColor = theme.countsContent.bubbleColor
const pressedBubbleColor = theme.countsContent.pressedBubble.bubbleColor
const pressedBubbleStrockColor = theme.countsContent.pressedBubble.strokeColor
const strokeColor = theme.countsContent.backgroundColor
const shadowColor = theme.countsContent.backgroundColor

export class Bubble {
  diameter = 0

  originalPosition = new Vector()
  position = new Vector()

  shadowOffset: number
  shadowBlur: number

  // Spring drawing constants
  maxDiameter = 0
  minDiameter = 0
  isOver = false
  isPressed = false

  // Spring simulation variables
  ds = 0 // Diameter
  vs = 0.0 // Velocity
  as = 0 // Acceleration
  f = 0 // Force

  velocity = new Vector()
  prevMouse = new Vector()
  isMoveOut = false
  isMoveIn = false
  isMaxPos = false

  constructor(x: number, y: number, diameter: number) {
    this.originalPosition.add(x, y)
    this.position.add(this.originalPosition)

    this.diameter = diameter

    this.shadowOffset = Math.round(diameter * 0.1)
    this.shadowBlur = this.shadowOffset

    this.maxDiameter = Math.round(diameter * 1.3)
    this.minDiameter = Math.round(diameter * 0.7)
    this.ds = diameter
  }

  display(p5: P5, i: number, isDebug = false) {
    p5.fill(bubbleColor)
    p5.stroke(strokeColor)
    p5.strokeWeight(1)

    const dc = p5.drawingContext as CanvasRenderingContext2D

    if (this.isPressed) {
      dc.shadowOffsetX = 0
      dc.shadowOffsetY = 0
      dc.shadowBlur = 0
      dc.shadowColor = shadowColor
    } else {
      dc.shadowOffsetX = this.shadowOffset
      dc.shadowOffsetY = -this.shadowOffset
      dc.shadowBlur = this.shadowBlur
      dc.shadowColor = shadowColor
    }

    p5.ellipse(this.position.x, this.position.y, this.ds)

    if (isDebug) {
      p5.fill('white')
      dc.shadowOffsetX = 0
      dc.shadowOffsetY = 0
      dc.shadowBlur = 0
      dc.shadowColor = 'none'
      p5.text(i, this.position.x - 8, this.position.y + 4)
    }

    if (this.isPressed) {
      p5.fill(pressedBubbleColor)
      p5.stroke(pressedBubbleStrockColor)
      p5.strokeWeight(3)

      p5.ellipse(this.position.x, this.position.y, this.diameter)
    }
  }

  isPointInCircle(p5: P5) {
    const r = this.diameter / 2
    const dx = this.position.x - p5.mouseX
    const dy = this.position.y - p5.mouseY
    const d = dx * dx + dy * dy
    return d <= r * r
  }

  mousePressed(p5: P5) {
    if (this.isOver) {
      this.isPressed = true
      this.ds = this.maxDiameter
      return true
    }
    return false
  }

  mouseReleased(p5: P5) {
    this.isPressed = false
  }

  update(p5: P5) {
    // Update the spring position
    if (!this.isPressed) {
      const f = -K * (this.ds - this.diameter) // f=-ky
      this.as = f / M // Set the acceleration, f=ma == a=f/m
      this.vs = D * (this.vs + this.as) // Set the velocity
      this.ds = this.ds + this.vs // Updated position
    }

    if (p5.abs(this.vs) < 0.1) {
      this.vs = 0.0
    }

    // Test if mouse if over the bubble
    if (this.isPointInCircle(p5)) {
      this.isOver = true
    } else {
      this.isOver = false
    }

    const mouse = new Vector()
    mouse.set(p5.mouseX, p5.mouseY)

    if (this.isMoveOut && !this.isMaxPos) {
      const target = new Vector()
      target.set(mouse)

      const acceleration = target.sub(this.position)
      acceleration.mult(-1).setMag(P)

      this.velocity.add(acceleration)
      this.velocity.limit(S)
      this.position.add(this.velocity)

      if (this.position.dist(this.originalPosition) >= this.diameter * MAX_D) {
        this.isMoveOut = false

        this.isMaxPos = true
        this.velocity.mult(0)
      }
    } else if (this.isMoveIn) {
      const target = new Vector()
      target.set(this.originalPosition)

      const acceleration = target.sub(this.position)
      acceleration.setMag(P)

      this.velocity.add(acceleration)
      this.velocity.limit(S)
      this.position.add(this.velocity)

      if (this.position.dist(this.originalPosition) <= this.diameter * MIN_D) {
        this.isMoveIn = false

        this.position.set(this.originalPosition)
        this.velocity.mult(0)
      }
    } else {
      // Precalc to speed up entire calc
      const dist = mouse.dist(this.originalPosition)
      const powerScope = this.diameter * PS

      if (dist < powerScope) {
        this.isMoveOut = true
        this.isMoveIn = false
      } else if (powerScope <= dist && dist < powerScope * PT) {
        this.isMoveOut = false
        this.isMoveIn = false
      } else if (powerScope * PT <= dist) {
        this.isMoveIn = false
        this.isMoveIn = true

        this.isMaxPos = false
      }
    }

    this.prevMouse.set(mouse)
  }

  isMouseMoved(mouse: Vector) {
    return !mouse.equals(this.prevMouse)
  }
}
