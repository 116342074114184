import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import CourseNavbarItemTitle from './CourseNavbarItemTitle'
import { NavItem } from './styled'
import type { CourseItem } from './types'

type CourseNavbarItemLinkProps = {
  item: CourseItem
}

const CourseNavbarItemLink = (props: CourseNavbarItemLinkProps) => {
  const { item } = props
  const location = useLocation()

  const isActive = useMemo(() => {
    return location.pathname.includes(item.to)
  }, [item, location])

  return (
    <NavItem to={item.to} active={+isActive} completed={+item.isCompleted}>
      <CourseNavbarItemTitle item={item} />
    </NavItem>
  )
}

export default CourseNavbarItemLink
