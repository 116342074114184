import { gql } from '@apollo/client'

export const GQL_GET_BABIES = gql`
  query GetBabies {
    babies(filters: {}) {
      _id
      firstName
      dateOfBirth
    }
  }
`
