import React from 'react'
import CourseNavbarActions from './CourseNavbarActions'
import CourseNavbarItem from './CourseNavbarItem'
import { useCourseNav } from './hooks/useCourseNav'
import { NoCourse } from './styled'

const CourseNavbar = () => {
  const courseNav = useCourseNav()

  return courseNav.children.length ? (
    <>
      <CourseNavbarActions />
      <CourseNavbarItem isRoot item={courseNav} />
    </>
  ) : (
    <NoCourse />
  )
}

export default CourseNavbar
