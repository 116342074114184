import { useCallback } from 'react'
import { useMutation } from '@apollo/client'

import type { Baby } from '../../../models/baby.model'
import { DeleteBabyDto, GQL_DELETE_BABY } from '../../../api/baby/delete-baby'
import ModalDialog from '../../ModalDialog/ModalDialog'
import { useUserStorage } from '../../../hooks'

type DeleteBabyDialogProps = {
  isOpen: boolean
  baby: Baby | null
  onClose: () => void
  onDelete: () => void
}

function DeleteBabyDialog(props: DeleteBabyDialogProps) {
  const { isOpen, baby, onClose, onDelete } = props

  const userStorage = useUserStorage()

  const [deleteBaby, { loading }] = useMutation<
    { deleteBaby: Baby },
    { payload: DeleteBabyDto }
  >(GQL_DELETE_BABY)

  const handleSubmit = useCallback(async () => {
    if (!baby) {
      return
    }

    await deleteBaby({
      variables: { payload: { _id: baby._id } },
      notifyOnNetworkStatusChange: true,
    })
    await userStorage?.deleteCourseCardsForBaby(baby._id)
    onClose()

    onDelete()
  }, [baby, deleteBaby, userStorage, onClose, onDelete])

  return (
    <ModalDialog
      isOpen={isOpen}
      isLoading={loading}
      header="Baby Deletion"
      body={`Do you really want to delete baby "${baby?.firstName}"?`}
      submitText="Delete"
      onSubmit={handleSubmit}
      onClose={onClose}
    />
  )
}

export default DeleteBabyDialog
