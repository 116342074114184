import { Colors } from '@blueprintjs/core'
import type { Theme } from './models/theme.model'

export default {
  countsSidebar: {
    backgroundColor: Colors.BLUE2,
    color: Colors.WHITE,
  },
  countsContent: {
    backgroundColor: Colors.BLACK,
    color: Colors.WHITE,
    bubbleColor: '#8E292C',
    pressedBubble: {
      bubbleColor: '#8c4647',
      strokeColor: '#8c3839',
    },
  },
} as Theme
