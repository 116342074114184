import { useCallback } from 'react'
import { useLazyQuery } from '@apollo/client'

import { GQL_GET_USER_INFO } from '../api/user/get-user-info'
import { setAuthData } from '../lib/auth'
import type { User, UserProfile, Baby } from '../models'
import {
  useBabies,
  useBaby,
  useLessonSettings,
  useSpeechSettings,
  useUser,
} from './'

export const useAuth = (): [
  authenticate: (accessToken: string, refreshToken: string) => Promise<boolean>,
  options: { loading: boolean },
] => {
  const [user, setUser] = useUser()
  const [babies, setBabies] = useBabies()
  const [speechSettings, setSpeechSettings] = useSpeechSettings()
  const [lessonSettings, setLessonSettings] = useLessonSettings()
  const [currentBaby, setCurrentBaby] = useBaby()
  const [getUserInfo, { loading: isUserInfoLoading }] = useLazyQuery<{
    userInfo: {
      user: User
      profile: UserProfile
      babies: Baby[]
    }
  }>(GQL_GET_USER_INFO, { notifyOnNetworkStatusChange: true })

  const authenticate = useCallback(
    async (accessToken: string, refreshToken: string): Promise<boolean> => {
      setAuthData(accessToken, refreshToken)

      const res = await getUserInfo()
      if (!res.data) {
        return false
      }

      const { user, profile, babies } = res.data.userInfo
      setUser(user)
      setBabies(babies)
      setSpeechSettings(profile.speechSettings)
      setLessonSettings(profile.lessonSettings)

      const newCurrentBaby =
        babies.find((b) => b._id === currentBaby?._id) || null
      setCurrentBaby(newCurrentBaby)

      return true
    },
    [
      currentBaby?._id,
      getUserInfo,
      setBabies,
      setCurrentBaby,
      setLessonSettings,
      setSpeechSettings,
      setUser,
    ],
  )

  return [authenticate, { loading: isUserInfoLoading }]
}
