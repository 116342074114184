import type P5 from 'p5'

export class MouseClick {
  isPressed = false
  lifespan = 0
  next = 0
  diameter = 0
  x = 0
  y = 0
  ds = 0

  constructor(diameter: number) {
    this.diameter = diameter
  }

  frameRateRatio(p5: P5) {
    const frameRate = p5.frameRate()
    return frameRate < 20 ? 60 / frameRate / 2 : 1
  }

  update(p5: P5) {
    this.lifespan = this.lifespan - 25 * this.frameRateRatio(p5)
    this.ds = this.ds + 7 * this.frameRateRatio(p5)

    this.lifespan = p5.constrain(this.lifespan, 0, 255)
    this.ds = p5.constrain(this.ds, 0, this.diameter * 1.5)
  }

  display(p5: P5, isDebug = false) {
    if (!this.next) return

    if (p5.millis() < this.next) {
      p5.stroke(127, this.lifespan)
      p5.fill(255, this.lifespan / 2)
      p5.ellipse(this.x, this.y, this.ds, this.ds)
    } else {
      this.next = 0
      this.lifespan = 0
      this.ds = 0
    }
  }

  mousePressed(p5: P5) {
    this.isPressed = true
    this.lifespan = 255
    this.next = p5.millis() + 500 // Limit drawing time.
    this.ds = 0

    this.x = p5.mouseX
    this.y = p5.mouseY
  }

  mouseReleased(p5: P5) {
    this.isPressed = false
  }
}
