import * as React from 'react'
import type { ThemeKey } from '../../models/theme.model'
import { ContentWrapper } from './styled'

type ContentProps = {
  children?: React.ReactNode
  themeKey?: ThemeKey
  padding?: number
}

function Content(props: ContentProps) {
  const { themeKey, children, padding } = props

  return (
    <ContentWrapper themeKey={themeKey} padding={padding}>
      {children}
    </ContentWrapper>
  )
}

export default Content
