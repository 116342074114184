import { Classes, Dialog, FormGroup } from '@blueprintjs/core'
import styled from '@emotion/styled'
import { withClass } from '../../hoc/withClass'

export const ModalDialogWrapper = styled(Dialog)`
  padding-bottom: 0;
`

export const Header = withClass(Classes.HEADING)(styled.strong`
  display: block;
  font-size: 1.5rem;
  margin-bottom: 1rem;
`)

export const Form = styled.form`
  height: 100%;
`

export const Footer = styled(FormGroup)`
  margin-top: 2rem;
  margin-bottom: 0;

  & .bp4-form-content {
    display: flex !important;
    justify-content: end;
  }
`
