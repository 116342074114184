import React, { useCallback, useEffect, useRef, useState } from 'react'
import Sketch from 'react-p5'
import type P5 from 'p5'
import type { Point } from '../../models/point.model'
import { useTheme } from '@emotion/react'
import type { CoursePosition, Theme } from '../../models'
import {
  useDebug,
  useFullScreen,
  useLesson,
  useLessonSettings,
} from '../../hooks'
import styled from '@emotion/styled'
import { Bubble } from './bubble'
import { useDebouncedCallback } from '../../hooks/use-debounced-callback'
import { MouseClick } from './mouseClick'

const MathCardWrapper = styled(Sketch)`
  position: absolute;
  width: 100%;
  height: 100%;
`

interface MathCardProps {
  card: string
  cardIndex: number
  points: Point[]
}

const triggerWindowResize = () => {
  window.dispatchEvent(new Event('resize'))
}

function MathCard(props: MathCardProps) {
  const { card, cardIndex, points } = props

  const theme = useTheme() as Theme
  const isDebug = useDebug()

  const { weekNum, dayNum, lessonNum } = useLesson()
  const [sceneWidth, setSceneWidth] = useState(0)
  const [sceneHeight, setSceneHeight] = useState(0)
  const [bubbles, setBubbles] = useState<Bubble[]>([])
  const [mouseClick, setMouseClick] = useState<MouseClick>()
  const [isWindowResized, setWindowResized] = useState(false)
  const [isFullScreen] = useFullScreen()
  const [lessonSettings] = useLessonSettings()
  const parentRef = useRef<Element | null>(null)
  const p5Ref = useRef<P5 | null>(null)

  useEffect(() => {
    if (!p5Ref.current) return

    if (lessonSettings.useBrowserFullScreen) {
      // Use complex conditions to avoid error:
      // Uncaught (in promise) TypeError:
      // Failed to execute 'exitFullscreen' on 'Document':
      // Document not active
      if (isFullScreen && !p5Ref.current.fullscreen())
        p5Ref.current.fullscreen(isFullScreen)
      else if (!isFullScreen && p5Ref.current.fullscreen())
        p5Ref.current.fullscreen(isFullScreen)
    }

    triggerWindowResize()
  }, [isFullScreen, lessonSettings.useBrowserFullScreen])

  const calcScene = useCallback(() => {
    if (!points) return

    const diameter = Math.floor(sceneHeight / 18)
    const renderPoints = points.map(
      (p) =>
        new Bubble(
          (p.x / window.screen.width) * sceneWidth,
          (p.y / window.screen.height) * sceneHeight,
          diameter,
        ),
    )
    setBubbles(renderPoints)

    setMouseClick(new MouseClick(diameter))
  }, [points, sceneHeight, sceneWidth])

  useEffect(() => {
    if (card || isWindowResized) {
      calcScene()
      setWindowResized(false)
    }
  }, [card, points, weekNum, dayNum, lessonNum, isWindowResized])

  const setup = useCallback((p5: P5, canvasParentRef: Element) => {
    p5Ref.current = p5
    parentRef.current = canvasParentRef

    p5.createCanvas(0, 0).parent(parentRef.current)

    triggerWindowResize()
  }, [])

  const draw = useCallback(
    (p5: P5) => {
      // console.log(p5.frameRate())
      p5.background(theme.countsContent.backgroundColor)

      bubbles.forEach((bubble, i) => {
        bubble.update(p5)
        bubble.display(p5, i, isDebug)
      })

      mouseClick?.update(p5)
      mouseClick?.display(p5, isDebug)
    },
    [isDebug, bubbles, mouseClick, theme.countsContent.backgroundColor],
  )

  const mousePressed = useCallback(
    (p5: P5) => {
      let isBubblePressed = false
      bubbles.forEach((point) => {
        isBubblePressed = isBubblePressed || point.mousePressed(p5)
      })
      if (!isBubblePressed) {
        mouseClick?.mousePressed(p5)
      }
    },
    [bubbles, mouseClick],
  )

  const mouseReleased = useCallback(
    (p5: P5) => {
      bubbles.forEach((point) => {
        point.mouseReleased(p5)
      })
      mouseClick?.mouseReleased(p5)
    },
    [bubbles, mouseClick],
  )

  const windowResized = useDebouncedCallback(
    (p5: P5) => {
      if (!parentRef.current) return

      const css = getComputedStyle(parentRef.current)
      const width = p5.round(p5.float(css.width))
      const height = p5.round(p5.float(css.height))

      if (isDebug) {
        console.log('Resize canvas due to window resize:', width, height)
      }
      p5.resizeCanvas(width, height, false)

      setSceneWidth(width)
      setSceneHeight(height)
      setWindowResized(true)
    },
    [isDebug],
    500,
  )

  return (
    <MathCardWrapper
      setup={setup}
      draw={draw}
      windowResized={windowResized}
      mousePressed={mousePressed}
      mouseReleased={mouseReleased}
    />
  )
}

export default MathCard
