import * as React from 'react'
import { ApolloProvider } from '@apollo/client'
import { ThemeProvider } from '@emotion/react'

import apolloClient from '../../lib/apollo-client'
import { browserHistory } from '../../hoc/BrowserRouter'
import type { LocationState } from '../../models'
import theme from '../../theme'

import AppRoutes from './AppRoutes'
import { useBaby, useUser } from '../../hooks'
import { useGlobalState } from '../../state'
import { UserStorage } from '../../lib/user-storage'
import { useEffect } from 'react'

console.log('App version:', process.env.REACT_APP_VERSION)
console.log('App branch:', process.env.REACT_APP_BRANCH)
console.log('App build number:', process.env.REACT_APP_BUILD_NUMBER)

const globalLogout = (event: StorageEvent) => {
  if (event.key === 'globalLogout') {
    browserHistory.push('/logout', {
      isGlobalLogout: true,
      prevLocation: {
        pathname: window.location.pathname,
        search: window.location.search,
        hash: window.location.hash,
      },
    } as LocationState)
  }
}
window.addEventListener('storage', globalLogout)

const App = () => {
  const [user] = useUser()
  const [baby] = useBaby()
  const [, setCourseCards] = useGlobalState('courseCards')

  // Load async state parts.
  useEffect(() => {
    if (!user) return
    if (!baby) return

    const userStorage = new UserStorage(user._id)
    userStorage.getCourseCards().then((courseCards) => {
      // If the db is empty then prepare initial data.
      // Initialized data are treated as "loaded state" flag
      // for state's async part "cards".
      if (!courseCards) {
        courseCards = {}
      }
      if (!courseCards[baby._id]) {
        courseCards[baby._id] = {
          counts: { cardPoints: {}, isPrepared: false },
          patterns: { cardPoints: {}, isPrepared: false },
          exercises: { cardPoints: {}, isPrepared: false },
        }
      }
      setCourseCards(courseCards)
    })
  }, [user, baby, setCourseCards])

  return (
    <>
      {/* <React.StrictMode> */}
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={theme}>
          <AppRoutes />
        </ThemeProvider>
      </ApolloProvider>
      {/* </React.StrictMode> */},
    </>
  )
}

export default App
