import * as React from 'react'
import { SpinnerWrapper } from './styled'

type SpinnerProps = {
  marginTop?: number
  marginRight?: number
  marginBottom?: number
  marginLeft?: number
}

function Spinner(props: SpinnerProps) {
  const { marginTop, marginRight, marginBottom, marginLeft } = props
  return (
    <SpinnerWrapper
      marginTop={marginTop}
      marginRight={marginRight}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
    />
  )
}

export default Spinner
