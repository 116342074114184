import { Button, ButtonGroup } from '@blueprintjs/core'
import { useState } from 'react'
import { useCourseNavigation, useLesson } from '../../hooks'
import SetCoursePositionDialog from './dialogs/SetCoursePositionDialog'
import { CourseNavbarActionsWrapper, Divider } from './styled'

const CourseNavbarActions = () => {
  const {
    playCurrentLesson,
    navigateNextLesson,
    setCoursePositionBeforeCurrentLesson,
    isLoading,
  } = useCourseNavigation()
  const { weekNum, dayNum, lessonNum } = useLesson()
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false)

  return (
    <CourseNavbarActionsWrapper>
      <ButtonGroup>
        <Button
          icon="play"
          title="Play the current lesson"
          disabled={!lessonNum}
          onClick={playCurrentLesson}
        />
        <Button
          icon="fast-forward"
          title="Go to the next lesson"
          onClick={navigateNextLesson}
        />
        <Divider />
        <Button
          icon="confirm"
          title="Set the course position to the current lesson"
          disabled={!lessonNum}
          onClick={() => setConfirmationDialogOpen(true)}
        />
      </ButtonGroup>

      <SetCoursePositionDialog
        isOpen={isConfirmationDialogOpen}
        isLoading={isLoading}
        position={{ weekNum, dayNum, lessonNum }}
        onClose={() => setConfirmationDialogOpen(false)}
        onSubmit={setCoursePositionBeforeCurrentLesson}
      />
    </CourseNavbarActionsWrapper>
  )
}

export default CourseNavbarActions
