import { Icon } from '@blueprintjs/core'
import styled from '@emotion/styled'
import { NavLink } from 'react-router-dom'
import { Colors } from '@blueprintjs/core'

interface NavItemProps {
  isactive: string
}

export const NavItem = styled(NavLink)<NavItemProps>`
  padding: 0.3rem 1rem;
  height: 2.5rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.isactive === 'true' ? Colors.LIGHT_GRAY4 : 'none'};

  &:hover {
    background-color: ${Colors.LIGHT_GRAY4};
  }
`

export const NavIcon = styled(Icon)`
  color: ${Colors.BLUE3} !important;
  margin-right: 0.5rem;
`

export const NavLabel = styled.span`
  font-size: 1rem;
  color: ${Colors.BLACK};
`
