import { useCallback } from 'react'
import type { CoursePosition } from '../../../models'
import ModalDialog from '../../ModalDialog/ModalDialog'

type SetCoursePositionDialogProps = {
  isOpen: boolean
  isLoading: boolean
  position: CoursePosition | null
  onClose: () => void
  onSubmit: () => Promise<void>
}

function SetCoursePositionDialog(props: SetCoursePositionDialogProps) {
  const { isOpen, isLoading, position, onClose, onSubmit } = props

  const handleSubmit = useCallback(async () => {
    if (!position) {
      return
    }

    await onSubmit()
  }, [onSubmit, position])

  return (
    <ModalDialog
      isOpen={isOpen}
      isLoading={isLoading}
      header="Course Position"
      body={`Do you really want to set the course position at week #${position?.weekNum}, day #${position?.dayNum} and lesson #${position?.lessonNum}?`}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  )
}

export default SetCoursePositionDialog
