import { Button, Checkbox, ControlGroup, FormGroup } from '@blueprintjs/core'
import { useCallback, useEffect, useState } from 'react'
import { useBaby, useLessonSettings, useUserStorage } from '../../hooks'
import { useGlobalState } from '../../state'
import ConfirmationDialog from '../ConfirmationDialog'
import Toaster from '../Toaster'

const LessonSettings = () => {
  const [lessonSettings, setLessonSettings] = useLessonSettings()
  const [useBrowserFullScreen, setBrowserFullScreen] = useState(
    lessonSettings.useBrowserFullScreen,
  )
  const userStorage = useUserStorage()
  const [, setCourseCards] = useGlobalState('courseCards')
  const [currentBaby, setCurrentBaby] = useBaby()
  const [resetCardsDialogIsOpen, setResetCardsDialogIsOpen] = useState(false)

  const handleBrowserFullScreenChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setBrowserFullScreen(e.target.checked)
    },
    [],
  )

  useEffect(() => {
    setLessonSettings({
      useBrowserFullScreen,
    })
  }, [setLessonSettings, useBrowserFullScreen])

  const resetCards = useCallback(async () => {
    await userStorage?.deleteAllCourseCards()

    // Reset state data.
    setCourseCards(null)

    // Reassign current baby to trigger reloading of state's async part "cards".
    // See App.tsx file for details.
    const tmp = currentBaby
    setCurrentBaby(null)
    setCurrentBaby(tmp)

    Toaster.show({
      intent: 'success',
      message: "Lessons' cards have been resetted.",
    })
  }, [currentBaby, setCourseCards, setCurrentBaby, userStorage])

  return (
    <>
      <div>
        <h2 style={{ marginTop: 0 }}>Lesson</h2>
        <FormGroup>
          <h3>Full Screen</h3>
          <ControlGroup vertical={false}>
            <Checkbox
              inline={true}
              checked={useBrowserFullScreen}
              onChange={handleBrowserFullScreenChange}
              label="Switch to browser's full screen mode when a lesson starts"
            />
          </ControlGroup>
          <h3>Cards</h3>
          <ControlGroup vertical={true}>
            <div>
              You can reset lessons' cards to regenerate them again in a random
              way.
            </div>
            <div style={{ marginTop: '.5rem' }}>
              <Button
                icon="trash"
                onClick={() => setResetCardsDialogIsOpen(true)}
                text="Reset Cards"
              ></Button>
            </div>
          </ControlGroup>
        </FormGroup>
      </div>
      <ConfirmationDialog
        isOpen={resetCardsDialogIsOpen}
        header="Lesson Settings"
        body="Do you really want to reset cards?"
        onClose={() => setResetCardsDialogIsOpen(false)}
        onConfirm={async () => {
          return resetCards()
        }}
      />
    </>
  )
}

export default LessonSettings
