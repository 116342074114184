import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  getVoices as getVoicesLib,
  speak as speakLib,
  SpeechSynthOptions,
} from '../lib/speech-synth'
import { useGlobalState } from '../state'

let speechSynthOptions: SpeechSynthOptions | null = null

const isDocumentCompleted = () => {
  const state = window.document.readyState
  return state === 'complete'
}

export const useSpeechSynth = () => {
  const [speechSettings, setSpeechSettings] = useGlobalState('speechSettings')
  const [isDomLoaded, setDomLoaded] = useState(isDocumentCompleted())

  useEffect(
    () =>
      sessionStorage.setItem('speechSettings', JSON.stringify(speechSettings)),
    [speechSettings],
  )

  const handleLoad = () => {
    setDomLoaded(true)
  }

  useEffect(() => {
    window.addEventListener('load', handleLoad)
    return () => {
      window.removeEventListener('load', handleLoad)
    }
  }, [])

  const getVoices = useCallback(() => {
    return isDomLoaded ? getVoicesLib() : []
  }, [isDomLoaded])

  const getVoice = useCallback(
    (voiceName: string): SpeechSynthesisVoice | undefined => {
      const voices = getVoices()
      const voice = voices.find((v) => v.name == voiceName)
      return voice
    },
    [getVoices],
  )

  speechSynthOptions = useMemo(() => {
    if (speechSettings) {
      const voice = getVoice(speechSettings.voiceName)
      if (voice) {
        return {
          voice,
          pitch: speechSettings.pitch,
          rate: speechSettings.rate,
        }
      }
    }
    return null
  }, [getVoice, speechSettings])

  const speak = useCallback((text: string) => {
    if (!speechSynthOptions) {
      console.warn('Cannot speak because the speech synthesis is not active.')
      return
    }
    speakLib(text, speechSynthOptions)
  }, [])

  return {
    speechSettings,
    setSpeechSettings,
    getVoices,
    speak,
  }
}
