import styled from '@emotion/styled'
import * as React from 'react'
import { useFullScreen } from '../../hooks'
import Navigation from '../Navigation'

const HeaderWrapper = styled.header`
  height: 3.2rem;
  top: 0;
`

function Header() {
  const [isFullScreen] = useFullScreen()

  return (
    <HeaderWrapper hidden={isFullScreen}>
      <Navigation />
    </HeaderWrapper>
  )
}

export default Header
