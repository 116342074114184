import { Button, Colors } from '@blueprintjs/core'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const LessonWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  // TODO: Use sidebar's theme.
  background-color: ${Colors.BLACK};
`

export const ControlPanel = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`

export const DebugPanel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  padding: 0.5rem;
`

const navigationButton = css`
  top: 0;
  background: transparent !important;
  outline: none;
  box-shadow: none !important;
  display: inline-flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
`

export const LeftButton = styled.div`
  ${navigationButton}
  left: 0;
  width: 40%;
  height: 100%;
`

export const CenterButton = styled.div`
  ${navigationButton}
  left: 40%;
  width: 20%;
  height: 100%;
`

export const RightButton = styled.div`
  ${navigationButton}
  left: 60%;
  width: 40%;
  height: 100%;
`

export const CenterButtons = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`

const centerButton = css`
  width: 14rem;
  padding: 1rem 2rem;
  opacity: 0.65;

  & svg {
    width: 3rem;
    height: 3rem;
  }
`

export const StartButton = styled(Button)`
  ${centerButton}
`

export const NextButton = styled(Button)`
  ${centerButton}
  margin-top: 2rem;

  @media (min-width: 768px) {
    margin-top: 0;
    margin-left: 2rem;
  }
`
