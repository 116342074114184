import { useEffect } from 'react'
import type { Baby } from '../models'
import { useGlobalState } from '../state'

export const useBabies = (): [
  babies: Baby[],
  setBabies: (babies: React.SetStateAction<Baby[]>) => void,
] => {
  const [babies, setBabies] = useGlobalState('babies')

  useEffect(
    () => sessionStorage.setItem('babies', JSON.stringify(babies)),
    [babies],
  )

  return [babies, setBabies]
}
