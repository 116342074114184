import React from 'react'
import ProfileNavbarItem from './ProfileNavbarItem'
import profileNavbar from './profile-navbar.config'
import type { NavbarItem } from '../../models/navbar-item.model'
import { useLocation } from 'react-router-dom'

const ProfileNavbar = () => {
  const location = useLocation()

  const isActive = (path: string) => {
    return location.pathname.includes(path)
  }

  return (
    <nav>
      {profileNavbar.map(
        (
          item: { label: string; icon: string; path: string },
          index: number,
        ) => {
          return (
            <ProfileNavbarItem
              key={`${item.label}-${index}`}
              item={item as NavbarItem}
              isActive={isActive(item.path)}
              margin={0}
            />
          )
        },
      )}
    </nav>
  )
}

export default ProfileNavbar
