import styled from '@emotion/styled'
import * as React from 'react'
import { Outlet } from 'react-router-dom'
import { useFullScreen } from '../../hooks'

const BodyWrapper = styled.div<{ isFullScreen: boolean }>`
  height: ${({ isFullScreen }) =>
    isFullScreen
      ? '100vh'
      : 'calc(100vh - 3.2rem - 0rem)'}; // totalHeight - header - footer
  overflow: hidden;
  display: flex;
`

function Body() {
  const [isFullScreen] = useFullScreen()

  return (
    <BodyWrapper isFullScreen={isFullScreen}>
      <Outlet />
    </BodyWrapper>
  )
}

export default Body
