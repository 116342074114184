import { Button, Card, Elevation } from '@blueprintjs/core'
import { FormEvent, useCallback } from 'react'
import { isError } from '../../models'
import Toaster from '../Toaster'
import { ModalDialogWrapper, Footer, Form, Header } from './styled'

type ModalDialogProps = {
  isOpen: boolean
  isLoading?: boolean
  header: React.ReactNode | (() => React.ReactNode)
  body?: React.ReactNode | (() => React.ReactNode)
  cancelText?: string
  submitText?: string
  onClose: () => void | Promise<void>
  onSubmit: () => void | Promise<void>
  onCancel?: () => void | Promise<void>
}

function ModalDialog(props: ModalDialogProps) {
  const {
    isOpen,
    isLoading,
    header,
    body,
    cancelText = 'Cancel',
    submitText = 'Confirm',
    onClose,
    onSubmit,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onCancel = async () => {},
  } = props

  const handleCancel = useCallback(async () => {
    await onCancel()
    await onClose()
  }, [onCancel, onClose])

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()

      try {
        await onSubmit()
        await onClose()
      } catch (e) {
        if (isError(e)) {
          Toaster.show({ intent: 'danger', message: e.message })
        }
      }
    },
    [onClose, onSubmit],
  )

  return (
    <ModalDialogWrapper isOpen={isOpen}>
      <Form onSubmit={handleSubmit}>
        <Card elevation={Elevation.TWO}>
          <Header>{typeof header === 'function' ? header() : header}</Header>
          {body && <>{typeof body === 'function' ? body() : body}</>}
          <Footer>
            <Button
              title={cancelText}
              intent="danger"
              icon="cross"
              disabled={isLoading}
              onClick={handleCancel}
              style={{ marginRight: '.5rem' }}
            >
              {cancelText}
            </Button>
            <Button
              title={submitText}
              intent="primary"
              icon={isLoading ? 'refresh' : 'tick'}
              disabled={isLoading}
              type="submit"
            >
              {submitText}
            </Button>
          </Footer>
        </Card>
      </Form>
    </ModalDialogWrapper>
  )
}

export default ModalDialog
