import React from 'react'
import type { NavbarItem } from '../../models/navbar-item.model'
import { NavIcon, NavItem, NavLabel } from './styled'

type ProfileMenuItemProps = {
  item: NavbarItem
  isActive: boolean
  margin: number
}

const ProfileNavbarItem = (props: ProfileMenuItemProps) => {
  const { item, isActive, margin } = props
  const { label, icon, path } = item

  return (
    <NavItem to={path} isactive={isActive.toString()}>
      <div style={{ marginLeft: `${margin}rem` }}></div>
      <NavIcon icon={icon} />
      <NavLabel>{label}</NavLabel>
    </NavItem>
  )
}

export default ProfileNavbarItem
