import { DependencyList, useCallback, useMemo, useRef } from 'react'

export function useDebouncedCallback<
  T extends (...args: any[]) => ReturnType<T>,
>(fn: T, deps: DependencyList, timeout: number) {
  const timeoutRef = useRef(0)
  const resultRef = useRef<ReturnType<T>>()
  const argsRef = useRef<unknown[]>([])

  const cb = useCallback(fn, [fn, ...deps])

  const debounced = useMemo(() => {
    const timerExpired = () => {
      resultRef.current = cb.call(null, ...argsRef.current)
    }

    const startTimer = (timerExpired: () => void) => {
      window.clearTimeout(timeoutRef.current)
      timeoutRef.current = window.setTimeout(timerExpired, timeout)
    }

    const func = (...args: any[]): ReturnType<T> | undefined => {
      argsRef.current = args
      startTimer(timerExpired)
      return resultRef.current
    }

    return func
  }, [cb, timeout])

  return debounced
}
