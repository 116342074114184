import {
  Button,
  ControlGroup,
  FormGroup,
  HTMLSelect,
  InputGroup,
  Label,
  Slider,
} from '@blueprintjs/core'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSpeechSynth } from '../../hooks'

const SpeachSynthSettings = () => {
  const { getVoices, speechSettings, setSpeechSettings, speak } =
    useSpeechSynth()
  const [text, setText] = useState(
    'Enter some text and press "play" button to hear it.',
  )
  const [rate, setRate] = useState(speechSettings.rate)
  const [pitch, setPitch] = useState(speechSettings.pitch)
  const [voiceName, setVoiceName] = useState(speechSettings.voiceName)

  const voices = useMemo(() => getVoices(), [getVoices])

  const handleTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setText(e.target.value)
    },
    [],
  )

  const handleVoiceChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setVoiceName(e.target.value)
    },
    [],
  )

  const handlePlayClick = useCallback(() => {
    speak(text)
  }, [speak, text])

  useEffect(() => {
    setSpeechSettings({
      voiceName,
      pitch,
      rate,
    })
  }, [pitch, rate, voiceName, setSpeechSettings])

  return (
    <>
      <div>
        <h2 style={{ marginTop: 0 }}>Speach Synth</h2>
        <FormGroup>
          <ControlGroup vertical={false}>
            <Label>
              Voice
              <HTMLSelect
                onChange={handleVoiceChange}
                value={voiceName}
                style={{ minWidth: '25rem' }}
              >
                {voices.map((v) => (
                  <option key={v.name} value={v.name}>
                    {v.name} ({v.lang}) {v.default ? ' -- DEFAULT' : null}
                  </option>
                ))}
              </HTMLSelect>
            </Label>
          </ControlGroup>
          <ControlGroup vertical={false}>
            <Label>
              Pitch
              <Slider
                min={0}
                max={1}
                stepSize={0.1}
                labelStepSize={0.5}
                value={pitch}
                onChange={setPitch}
              />
            </Label>
          </ControlGroup>
          <ControlGroup vertical={false}>
            <Label>
              Rate
              <Slider
                min={0.5}
                max={2}
                stepSize={0.1}
                labelStepSize={0.5}
                value={rate}
                onChange={setRate}
              />
            </Label>
          </ControlGroup>
        </FormGroup>
        <FormGroup>
          <ControlGroup vertical={false}>
            <InputGroup
              type="text"
              value={text}
              onChange={handleTextChange}
              style={{ minWidth: '25rem' }}
            />
            <Button onClick={handlePlayClick} icon="play" title="Play" />
          </ControlGroup>
        </FormGroup>
      </div>
    </>
  )
}

export default SpeachSynthSettings
