import { ApolloLink, ApolloClient, fromPromise } from '@apollo/client'
import { onError } from '@apollo/client/link/error'

import { getRefreshToken, setAuthData } from '../../auth'
import { GQL_REFRESH } from '../../../api/auth/refresh'
import cache from '../cache'
import authLink from './auth-link'
import httpLink from './http-link'

type Callback = () => void

let isRefreshing = false
let pendingRequests: Callback[] = []

const setIsRefreshing = (value: boolean) => {
  isRefreshing = value
}

const addPendingRequest = (pendingRequest: Callback) => {
  pendingRequests.push(pendingRequest)
}

const refreshTokenApiClient = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
  cache,
  credentials: 'include',
})

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback())
  pendingRequests = []
}

const refreshTokens = async () => {
  const currentRefreshToken = getRefreshToken()
  if (!currentRefreshToken) return

  const {
    data: {
      refresh: { accessToken, refreshToken },
    },
  } = await refreshTokenApiClient.mutate({
    mutation: GQL_REFRESH,
    variables: { refreshToken: currentRefreshToken },
  })

  setAuthData(accessToken, refreshToken)
}

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (
    graphQLErrors?.length &&
    graphQLErrors[0].extensions.code === 'UNAUTHENTICATED'
  ) {
    if (!isRefreshing) {
      setIsRefreshing(true)

      return fromPromise(
        refreshTokens().catch(() => {
          resolvePendingRequests()
          setIsRefreshing(false)

          window.location.href = '/logout'
        }),
      ).flatMap(() => {
        resolvePendingRequests()
        setIsRefreshing(false)

        return forward(operation)
      })
    } else {
      return fromPromise(
        new Promise<void>((resolve) => {
          addPendingRequest(() => resolve())
        }),
      ).flatMap(() => {
        return forward(operation)
      })
    }
  }
})

export default errorLink
