import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import type { LocationState } from '../models'

export const useNavigation = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const navigateToApp = useCallback(() => {
    const locationState = location.state as LocationState
    const redirectTo = locationState?.prevLocation?.pathname
    if (redirectTo) {
      navigate(redirectTo)
    } else {
      navigate('/')
    }
  }, [location.state, navigate])

  const navigateToLogout = useCallback(() => {
    navigate('/logout')
  }, [navigate])

  return [navigateToApp, navigateToLogout]
}
