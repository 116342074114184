import { useGlobalState } from '../state'

export const useFullScreen = (): [
  isFullScreen: boolean,
  setFullScreen: (isFullScreen: React.SetStateAction<boolean>) => void,
] => {
  const [isFullScreen, setFullScreen] = useGlobalState('isFullScreen')

  return [isFullScreen, setFullScreen]
}
